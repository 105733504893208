<template>
	<div class="enterprise-stores">
		<div class="stores-bg">
			<div class="step-box">
				<el-steps :active="active" finish-status="success" align-center>
					<el-step :title="$t('signIn.setLoginInformation')"></el-step>
					<el-step :title="$t('signIn.validation')"></el-step>
					<el-step :title="$t('base.complete')"></el-step>
				</el-steps>
			</div>
			<div class="enterprise-stores-child">
				<div class="reason-form">
					<div class="form-box" v-show="active===0">
						<el-form :model="ruleForm" :rules="rules" label-position="top" ref="ruleForm" label-width="auto" class="">
							<h3 style="padding:30px 0 50px;">{{ $t('signIn.registerInfo') }}</h3>
							<el-form-item :label="$t('signIn.mobilePhoneNumber')" class="phone-box" prop="phone">
								<el-input v-model="ruleForm.phone" :placeholder="$t('base.pleaseEnterPhoneNumber')">
									<el-select style="width: 70px;" v-model="ruleForm.area" slot="prepend" :placeholder="$t('base.pleaseSelectA')">
										<el-option v-for="(item,index) in areaCodeArray" :key="index" :label="item.areaCode" :value="item.areaCode"></el-option>
									</el-select>
								</el-input>
							</el-form-item>
							<el-form-item label="图形验证码" prop="uCode">
								<el-input v-model="ruleForm.uCode" placeholder="请输入验证码">
									<template slot="append">
										<img :src="ruleForm.img" style="height:34px;cursor: pointer;" @click="getUCode"/>
									</template>
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('signIn.password')" prop="pwd" class="password-box">
								<el-input :placeholder="$t('signIn.passwordsAreCaseSensitive')" v-model="ruleForm.pwd" show-password></el-input>
							</el-form-item>
							<el-form-item :label="$t('base.confirmPassword')" prop="confirmPwd" class="password-confirmation-box">
								<el-input :placeholder="$t('base.confirmPassword')" v-model="ruleForm.confirmPwd" show-password></el-input>
								<span class="password-tip"><i class="el-icon-info"></i> {{ $t('signIn.anAlphanumericSymbol') }}</span>
							</el-form-item>
							<el-form-item class="d-flex justify-content-center mar-top-60 w-100">
								<el-button style="width:100%" type="primary" @click="submitForm('ruleForm')">{{ $t('signIn.nextStepToVerify') }}</el-button>
							</el-form-item>
						</el-form>
					</div>
					<div class="form-box" v-if="active===1">
						<div class="demo-ruleForm">
							<div class="verification-code-title d-flex flex-direction-column align-items-center">
								<h3 style="padding:30px 0;">{{ $t('signIn.verifyMobilePhoneNumber') }}</h3>
								<p class="pad-50-0">{{ $t('signIn.sentToMobilePhoneNumber') }} +{{ ruleForm.area }}{{ ruleForm.phone }} {{ $t('signIn.verificationCodeS') }}</p>
							</div>
							<div class="d-flex justify-space-between">
								<input v-for="(item,index) in digits" :key="index" :ref="`ref${index}`" class="input" v-model="item.value" type="text" oninput="value=value.replace(/[^\d]/g,'')" @input="onInput(index)" @keyup.delete="onDelete(index)" maxlength="1" />
							</div>
							<div class="d-flex justify-content-center pad-top-50 w-100 mar-bottom-10">
								<el-button type="primary" style="width:100%" @click="checkingBtn()">{{ $t('signIn.verifyMobilePhoneNumber') }}</el-button>
							</div>
							<div class="d-flex justify-space-between w-100">
								<div class="cursor-pointer" @click="previousStep()"><i class="el-icon-arrow-left"></i> {{ $t('base.previousStep') }}</div>
								<div class="d-flex flex-direction-column align-items-end">
									<span v-if="sendMsgDisabled">{{codeTime}}</span>
									<span v-if="!sendMsgDisabled" class="cursor-pointer" @click="reverify">重新验证</span>
								</div>
							</div>
						</div>
					</div>
					<div class="d-flex flex-direction-column align-items-center" v-else-if="active===2">
						<div class="d-flex flex-direction-column align-items-center">
							<span class="pad-20-0 fs-16 font-weight-bold">{{ $t('signIn.registeredSuccessful') }} ！</span>
							<img class="success-logo" width="60" height="60" src="@/assets/image/home/ic_gong.png" alt="">
						</div>
						<div class="d-flex flex-direction-column align-items-center pad-20-0">
							<p class="pad-top-20 font-weight-bold">{{ $t('signIn.accountCreatedSuccessful') }}+{{ ruleForm.area }}{{ ruleForm.phone }}</p>
						</div>
						<div class="d-flex flex-direction-column justify-content-center align-items-center pad-top-50 w-100 mar-bottom-10 login-btn">
							<p class="pad-top-20">{{ $t('signIn.pleaseCompleteInApplication') }}</p>
							<div class="mat-20 w-100 d-flex justify-content-center">
								<el-button type="primary" style="width:100%" @click="logInNow()">立即下载</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios'
	export default {
		data() {
			// 邮箱验证
			// let validEmail = (rule, value, callback) => {
			//   let reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
			//   if (reg.test(value)) {
			//     callback();
			//   } else {
			//     return callback(new Error(this.$t('signIn.pleaseEnterCorrectEmail')));
			//   }
			// };
			// 区号手机号验证
			let validPhone = (rule, value, callback) => {
				const reg = this.ruleForm.area == 86 ? /^1\d{10}$/ : this.ruleForm.area == 63 ? /^9\d{9}$/ : this.ruleForm.area == 60 ? /^(([145](-|\s)?\d{7,8})|([236789](-|\s)?\d{7}))$/ : this.ruleForm.area == 66 ? /^[0-9]*$/ : this.ruleForm.area == 65 ? /^[0-9]*$/ : ''
				if (reg.test(value)) {
					callback();
				} else {
					return callback(new Error(this.$t('signIn.pleaseEnterCorrectPhone')));
				}
			};
			// 密码验证
			const rest = /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{8,16}$/;
			let validatePass = (rule, value, callback) => {
				if (!rest.test(value)) {
					callback(new Error(this.$t('signIn.doNotConformToPasswordFormat')));
				} else {
					callback();
				}
			};
			// 密码是否一致验证
			let validatePassRepeat = (rule, value, callback) => {
				if (value === '') {
					callback(new Error(this.$t('signIn.pleaseEnterPasswordAgain')));
				} else if (value !== this.ruleForm.pwd) {
					callback(new Error(this.$t('signIn.enterPasswordTwice')));
				} else if (!rest.test(value)) {
					callback(new Error(this.$t('signIn.doNotConformToPasswordFormat')));
				} else {
					callback();
				}
			};
			return {
				active: 0,
				ruleForm: {
					area: '86',
					phone: '',
					pwd: '',
					confirmPwd: '',
					code: '',
					uCode: '',
					uuid: '',
					img: '',
				},
				areaCodeArray: [{
						areaCode: '86'
					},
					{
						areaCode: '63'
					},
					{
						areaCode: '60'
					},
					{
						areaCode: '66'
					},
					{
						areaCode: '65'
					},
				],
				codeTime: 59,
				digits: [{
					value: ''
				}, {
					value: ''
				}, {
					value: ''
				}, {
					value: ''
				}, {
					value: ''
				}, {
					value: ''
				}],
				sendMsgDisabled: false,
				rules: {
					phone: [{
							required: true,
							message: this.$t('signIn.pleaseEnterPhoneNumber'),
							trigger: 'blur'
						},
						{
							required: true,
							trigger: 'blur',
							validator: validPhone
						},
					],
					pwd: [{
							required: true,
							message: this.$t('signIn.pleaseEnterPassword'),
							trigger: 'blur'
						},
						{
							required: true,
							trigger: 'blur',
							validator: validatePass
						}
					],
					uCode:[{
						required:true,message:'请输入',trigger:'blur'
					}],
					confirmPwd: [{
							required: true,
							message: this.$t('signIn.pleaseEnterRepeatPassword'),
							trigger: 'blur'
						},
						{
							required: true,
							trigger: 'blur',
							validator: validatePassRepeat
						}
					],
				}
			}
		},
		mounted(){
			this.getUCode();
		},
		methods: {
			//获取图形码
			getUCode(){
				this.ruleForm.img='';
				this.ruleForm.uuid='';
				axios.get('https://my.api.merchant.gogo2go.com/common/captcha?type=1').then(res=>{
					this.ruleForm.img=res.data.data.img;
					this.ruleForm.uuid=res.data.data.uuid;
				})
			},
			reverify(){
				this.active=0;
				this.getUCode()
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						axios.post('https://my.api.merchant.gogo2go.com/common/sms/send',{
							phone:this.ruleForm.phone,
							area:this.ruleForm.area,
							type:1,
							code:this.ruleForm.uCode,
							uuid:this.ruleForm.uuid,
						}).then(res=>{
							if(res.data.code == 200){
								this.active = 1;
								this.sendMsgDisabled = true;
								this.codeTime = 59;
								this.$message.success('已发送');
								let codeInterval = window.setInterval(() => {
									if ((this.codeTime--) <= 1) {
										this.codeTime = 59;
										this.sendMsgDisabled = false;
										window.clearInterval(codeInterval);
									}
								}, 1000);
							}else{
								this.active=0;
								this.ruleForm.uCode='';
								this.getUCode();
								this.$message.error(res.data.message);
							}
						})
					} else {
						return false;
					}
				});
			},
			onInput(index) {
				// index < 5 ，如果是第6格，不触发光标移动至下一个输入框。
				if (this.digits[index].value && index < 5) {
					this.$refs['ref' + (index + 1)][0].focus()
				}
			},
			onDelete(index) {
				// 如果是第1格，不触发光标移动至上一个输入框
				if (index > 0) {
					this.$refs['ref' + (index - 1)][0].focus()
				}
			},
			previousStep() {
				this.active = 0
			},
			checkingBtn() {
				let filter_res = this.digits.filter(item => item.value != '')
				const codeValue = ((this.digits.map((item) => item.value)).toString()).replace(/,/g, "")
				if (filter_res.length > 5) {
					axios.post('https://my.api.merchant.gogo2go.com/auth/registry',{
						area:this.ruleForm.area,
						phone:this.ruleForm.phone,
						pwd:this.ruleForm.pwd,
						code:codeValue,
					}).then(res=>{
						if (res.data.code===200){
							this.active = 2
							this.$message.success('已注册');
						}else{
							this.active = 0
						}
					})
					// const data = {
					// 	area: this.ruleForm.area,
					// 	phone: this.ruleForm.phone,
					// 	pwd: this.ruleForm.pwd,
					// 	confirmPwd: this.ruleForm.confirmPwd,
					// 	code: codeValue
					// }
					// verificationCode(data).then(res => {
					// 	if (res.status === 200) {
					// 		this.active = 2
					// 		this.$message.success(res.message)
					// 	} else {
					// 		this.active = 0
					// 	}
					// })
				} else {
					this.$message(this.$t('signIn.pleaseEnterCompleteVerificationCode'))
				}
			},
			logInNow() {
				
			}
		}
	}
</script>
<style scoped>
	/deep/.el-input-group__append{
		padding: 0;
		background-color: #ffffff;
	}
	.enterprise-stores {
		background: #f7f9ff;
		padding-top: 60px;
		box-sizing: border-box;
	}

	.stores-bg {
		background: url('../../../assets/image/login/bg_img_apply.png') no-repeat;
		background-size: 100% 180px;
	}

	.enterprise-stores-child {
		padding: 0 20%;
		background-color: #f7f9ff;
	}

	.reason-form {
		padding: 20px 0 80px;
		min-width: 375px;
		background: #fff;
		position: relative;
		top: -20px;
		border-radius: 4px;
	}

	.form-box {
		padding: 0 10%;
		/* width: 80%; */
	}

	.demo-ruleForm {
		padding: 10px 20%;
	}

	.input {
		width: 2.381rem;
		height: 2.381rem;
		font-size: 0.8571rem;
		color: #333333;
		text-align: center;
		outline: none;
		border: solid 0.0476rem #d2d2d2;
		border-top: 0rem;
		border-left: 0rem;
		border-right: 0rem;
	}

	.password-tip {
		font-size: 0.5714rem;
		font-weight: 500;
		color: #7c7f86;
		/*margin-left: 100px;*/
	}

	.success-logo {
		padding: 20px 0;
	}

	.password-confirmation-box {
		margin-top: 0.9524rem;
	}

	.el-form-item>>>.el-form-item__content {
		width: 100% !important;
	}
</style>
